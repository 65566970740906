import { ChannelData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import settings from '../../../../../params/canal/settings.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageEspaceCanalUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { channelId, espaceId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        displayAdd={false}
        displayClose={false}
        displayNavigation={false}
        displayRemove={false}
        docId={channelId}
        itemPathnamePrefix={`/espaces/${espaceId}/canaux/`}
        model={
          new ChannelData({
            espaceId,
            itemFieldTitle: 'libelle',
            params: settings,
          })
        }
        name="item"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceCanalUpdate);
